import React, { useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { css } from 'emotion';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { InputField } from '../components/InputField';
import { ButtonContainer } from '../components/ButtonContainer';
import { Loader } from '../components/Loader';
import { useCreateInitialQuote } from '../../api/api';
import { CustomButton } from '../components/CustomButton';
import { InnerGrid } from '../components/InnerGrid';

const customAlignment = css`
  flex-direction: column;
  @media only screen and (max-width: 879px) {
    width: 100%;
  }
  @media only screen and (min-width: 880px) {
    flex-direction: row;
    gap: 2.5rem;
  }
  @media only screen and (min-width: 768px) {
    justify-content: flex-end;
  }
`;
const threeRowStyle = css`
  @media (min-width: 768px) {
    padding-right: 0;
    grid-template-columns: Max(25vw, 14.5rem) 1fr Max(25vw, 14.5rem) !important;
  }
`;

const formContainerStyle = css`
  background-color: #ff7401;
  border-radius: 15px;
  padding: 1.5rem;
  margin: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 0;

  @media (max-width: 768px) {
    margin: auto 1.25rem;
  }
`;

const titleStyle = css`
  color: #ffffff;
  font-size: 2.25rem;
  font-family: 'Lato', sans-serif;
  margin-bottom: 1.15rem;
  text-align: center;
`;

const infoStyle = css`
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  line-height: 1.8;
  text-align: center;
  margin: 0 1.5rem 2rem;
`;

const nameContainerStyle = css`
  display: flex;
  width: 100%;
`;

const spaceBetweenStyle = css`
  width: 10%;
`;

const loaderStyle = css`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const salesforceValidationScheme = yup.object().shape({
  firstName: yup.string().required('First name is required field.'),
  lastName: yup.string().required('Last name is required field.'),
  phone: yup.string().required('Phone is required field.'),
  email: yup
    .string()
    .email('Email must be a valid email.')
    .required('Email is required field.')
});

const salesforceInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: ''
};

export const SalesforceDetails = () => {
  const { mutate: createQuote, isLoading: createQuoteLoading } = useCreateInitialQuote();
  const previousQuote = localStorage.getItem('previousQuote');
  const history = useHistory();
  const [newQuote, setnewQuote] = useState(!localStorage.previousQuote);
  const handleContinue = () => {
    if (previousQuote) {
      const parsedPreviousQuote = JSON.parse(previousQuote);
      if (get(parsedPreviousQuote, 'leadId')) {
        history.replace(`/lead/${get(parsedPreviousQuote, 'leadId')}`);
      }
      if (get(parsedPreviousQuote, 'opportunityId')) {
        history.replace(`/opportunity/${get(parsedPreviousQuote, 'opportunityId')}`);
      }
    }
  };

  return (
    <Formik
      initialValues={salesforceInitialValues}
      validationSchema={salesforceValidationScheme}
      onSubmit={values => {
        createQuote(values);
      }}
    >
      {props => (
        <InnerGrid customAlignment={threeRowStyle}>
          <div />
          {createQuoteLoading && (
            <div className={loaderStyle}>
              <Loader message="Loading your quote..." />
            </div>
          )}
          {!createQuoteLoading && (
            <div className={formContainerStyle}>
              <h3 className={titleStyle}>Start Your Quote</h3>
              {newQuote && (
                <p className={infoStyle}>Let us have a few details so we can get started with your quote…</p>
              )}
              {newQuote && (
                <>
                  <div className={nameContainerStyle}>
                    <InputField name="firstName" id="firstName" placeholder="First Name" salesForce fullwidth />
                    <div className={spaceBetweenStyle} />
                    <InputField name="lastName" id="lastName" placeholder="Last Name" salesForce fullwidth />
                  </div>
                  <InputField name="email" id="email" placeholder="Email" salesForce fullwidth />
                  <InputField name="phone" id="phone" placeholder="Phone" type="number" salesForce fullwidth />
                </>
              )}
              {!newQuote && (
                <>
                  <p className={infoStyle}>Would you like to continue with the previous quote or start a new one?</p>
                </>
              )}
              <ButtonContainer customAlignment={customAlignment}>
                <CustomButton
                  handleClick={() => {
                    if (newQuote) {
                      props.handleSubmit();
                    } else {
                      setnewQuote(true);
                    }
                  }}
                  label="Start New Quote"
                  salesForce
                />
                {previousQuote && !newQuote && (
                  <CustomButton handleClick={handleContinue} label="Continue Quote" salesForce />
                )}
                {previousQuote && newQuote && (
                  <CustomButton handleClick={() => setnewQuote(false)} label="Back" salesForce />
                )}
              </ButtonContainer>
            </div>
          )}
        </InnerGrid>
      )}
    </Formik>
  );
};
